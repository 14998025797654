import { Suspense } from "react";
import { Toaster } from "react-hot-toast";
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";

import Router from "routes";
import AuthProvider from "providers/AuthProvider";
import { createCustomTheme } from "theme";
import { layoutSelector } from "redux/layout";
import { useSelector } from "react-redux";

function App() {
  const { layout } = useSelector(layoutSelector);
  
  const theme = createCustomTheme({
    theme: layout.theme,
    direction: layout.direction,
    responsiveFontSizes: layout.responsiveFontSizes,
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense fallback={<div> loading...</div>}>
          <AuthProvider>
            <Router />
          </AuthProvider>
          <Toaster position="top-right" />
        </Suspense>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
