import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_API;
const TOKEN_KEY_IN_HEADER = "Authorization";
const TOKEN_KEY_IN_LOCAL_STORAGE = "access-token";

// check if we have jwt in local storage, if we have, then add it to the header.
export function isAuthenticated() {
  if (typeof window !== "undefined") {
    // get the authentication token from sessionStorage if it exists
    let token = sessionStorage.getItem(TOKEN_KEY_IN_LOCAL_STORAGE);
    if (!token) {
      // if token does not exist in sessionStorage, try localStorage
      token = localStorage.getItem(TOKEN_KEY_IN_LOCAL_STORAGE);
    }
    return token;
  }
  return null;
}

const baseQuery = fetchBaseQuery({
  baseUrl,
  // credentials: 'include',
  prepareHeaders: (headers) => {
    if (typeof window !== "undefined") {
      // if we have jwt, here u should update the access token
      const token = isAuthenticated();
      if (token) {
        headers.set(TOKEN_KEY_IN_HEADER, token);
      }
    }
    return headers;
  },
});

// try to execute the req, if it fails logout, and redirect to login.
const baseQueryWithAuth = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);
  if (result.error?.status === 403 || result.error?.status === 401) {
    // non authorized, then redirect to login page.
    if (typeof window !== "undefined") {
      // if we have jwt, here u should update the access token
      localStorage.removeItem(TOKEN_KEY_IN_LOCAL_STORAGE);
    }
  }
  return result;
};

// create api
export const apiSlice = createApi({
  baseQuery: baseQueryWithAuth,
  tagTypes: ["auth", "card", "cards", "links", "leads"],
  endpoints: (_builder) => ({}),
});

export const clientLogout = () => {
  // remove jwt from local storage
  localStorage.removeItem(TOKEN_KEY_IN_LOCAL_STORAGE);
  // remove jwt from session storage
  sessionStorage.removeItem(TOKEN_KEY_IN_LOCAL_STORAGE);
};

export const login = async (token, rememberMe) => {
  if (rememberMe) {
    localStorage.setItem(TOKEN_KEY_IN_LOCAL_STORAGE, `Bearer ${token}`);
  } else {
    sessionStorage.setItem(TOKEN_KEY_IN_LOCAL_STORAGE, `Bearer ${token}`);
  }
};
