import { forwardRef } from "react";
import {
  Box,
  Fade,
  Stack,
  Button,
  Dialog,
  Typography,
  IconButton,
  DialogActions,
  DialogContent,
} from "@mui/material";

import { CloseOutlined } from "@mui/icons-material";

const Transition = forwardRef(function Transition(props, ref) {
  return <Fade ref={ref} {...props} />;
});

const Modal = ({ open, onClose, title, children, handleSubmit }) => {
  return (
    <Dialog
      fullWidth
      open={open}
      scroll="body"
      maxWidth="md"
      onClose={onClose}
      TransitionComponent={Transition}
      onBackdropClick={onClose}
    >
      <DialogContent
        sx={{
          pb: 2,
          position: "relative",
          px: { xs: 2, sm: 5 },
          pt: { xs: 2, sm: 4.5 },
        }}
      >
        <IconButton
          size="small"
          onClick={onClose}
          sx={{ position: "absolute", right: "1rem", top: "1rem" }}
        >
          <CloseOutlined />
        </IconButton>
        <Box sx={{ mb: 8, textAlign: "center" }}>
          <Typography variant="h5" sx={{ mb: 3 }}>
            {title}
          </Typography>
        </Box>
        {children}
      </DialogContent>
      <DialogActions sx={{ pb: { xs: 2, sm: 4.5 } }}>
        <Stack direction="row" spacing={2}>
          <Button variant="outlined" onClick={onClose}>
            Discard
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onClose();
              handleSubmit();
            }}
          >
            Submit
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
