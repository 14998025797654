import { Box, styled, useTheme } from "@mui/material";

import LayoutDrawer from "./LayoutDrawer";
import MultiLevelMenu from "./MultiLevelMenu";
import { FlexBox, Scrollbar } from "components";
import { lightTheme } from "constants";

const TOP_HEADER_AREA = 100;

const NavWrapper = styled(Box)(() => ({
  paddingLeft: 16,
  paddingRight: 16,
  height: "100%",
}));

const MobileSidebar = (props) => {
  const { sidebarCompact, showMobileSideBar, setShowMobileSideBar } = props;
  const theme = useTheme();
  return (
    <LayoutDrawer open={showMobileSideBar} onClose={setShowMobileSideBar}>
      <Box p={2} height={TOP_HEADER_AREA}>
        <FlexBox ml={3} height="100%">
          <img
            src={
              lightTheme(theme)
                ? "/static/logo/logo.svg"
                : "/static/logo/logoDark.svg"
            }
            alt="logo"
            width={160}
          />
        </FlexBox>
      </Box>

      <Scrollbar
        autoHide
        clickOnTrack={false}
        sx={{
          overflowX: "hidden",
          maxHeight: `calc(100vh - ${TOP_HEADER_AREA}px)`,
        }}
      >
        <NavWrapper compact={sidebarCompact}>
          <MultiLevelMenu sidebarCompact={false} />
        </NavWrapper>
      </Scrollbar>
    </LayoutDrawer>
  );
};

export default MobileSidebar;
