// import { useEffect } from "react";

import LoadingScreen from "components/LoadingScreen";
import { setUserData, useProfileQuery } from "pages/auth/store";
import { Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isLoading, data } = useProfileQuery();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(setUserData(data));
      if (location.pathname === "/") await navigate("/");
      else await navigate(location.pathname);
    };
    if (data) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (isLoading) return <LoadingScreen />;

  return <Suspense fallback={<LoadingScreen />}>{children}</Suspense>;
};

export default AuthProvider;
