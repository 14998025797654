// ** Router imports
import { Navigate, useRoutes } from "react-router-dom";

// ** Routes

import { lazy } from "react";
import DashLayout from "layouts/DashLayout";

const Login = lazy(() => import("../pages/auth/login"));
const Register = lazy(() => import("../pages/auth/register"));

const Card = lazy(() => import("../pages/dash/Card/Card"));
const Cards = lazy(() => import("../pages/dash/Card/Cards"));
const Leads = lazy(() => import("../pages/dash/Lead/Leads"));
const Pricing = lazy(() => import("../pages/others/Pricing"));

export const authRoutes = [
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "register",
    element: <Register />,
  },
];

export const dashRoutes = [
  {
    path: "card/:id",
    element: <Card />,
  },
  {
    path: "cards",
    element: <Cards />,
  },
  {
    path: "leads",
    element: <Leads />,
  },
  {
    path: "pricing",
    element: <Pricing />,
  },
];

const Router = () => {
  const token = localStorage.getItem("access-token");

  const initRoute = () => {
    if (token) {
      return "/cards";
    } else {
      return "/login";
    }
  };

  const routes = useRoutes([
    {
      path: "/",
      element: <Navigate replace to={initRoute()} />,
    },
    ...authRoutes,
    {
      path: "/",
      element: <DashLayout />,
      children: [...dashRoutes],
    },
  ]);

  return routes;
};

export default Router;
