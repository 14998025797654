// ** Redux Imports
import { apiSlice, clientLogout } from "redux/api";
import { createSlice } from "@reduxjs/toolkit";

const authApi = apiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      login: builder.mutation({
        query: (credentials) => ({
          url: "/api/auth/local",
          method: "POST",
          body: { ...credentials },
        }),
        providesTags: ["auth"],
      }),
      register: builder.mutation({
        query: (credentials) => ({
          url: "/api/auth/local/register",
          method: "POST",
          body: { ...credentials },
        }),
        providesTags: ["auth"],
      }),
      profile: builder.query({
        query() {
          return "/api/users/me?populate=*";
        },
        providesTags: ["auth"],
      }),
      logout: builder.mutation({
        query: () => ({
          url: "/auth/logout",
          method: "POST",
        }),
        invalidateTags: ["auth"],
        async onQueryStarted(_, { queryFulfilled }) {
          try {
            await queryFulfilled;
            clientLogout();
          } catch {
            /* empty */
          }
        },
        invalidatesTags: ["auth"],
      }),
    };
  },
});

const initialState = {
  user: {},
  isAuth: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.isAuth = true;
      state.user = action.payload.data ?? {};
    },
    setUserData: (state, action) => {
      state.isAuth = true;
      state.user = action.payload ?? {};
    },
    resetAuth: () => {
      return { ...initialState };
    },
  },
});

export const getUserData = (state) => state.auth.user;
export const checkIsAuth = (state) => state.auth.isAuth;

export const authSelector = (state) => state.auth;
export const { setAuth, resetAuth, setUserData } = authSlice.actions;
export const {
  useProfileQuery,
  useLoginMutation,
  useLogoutMutation,
  useRegisterMutation,
} = authApi;

export default authSlice.reducer;
