import { Outlet } from "react-router";
import { Fragment, useState } from "react";

import Sidebar from "./components/Sidebar";
import LayoutBodyWrapper from "./components/LayoutBodyWrapper";
import DashboardHeader from "./components/Header";
// import DashboardHeader from "./components/Header";
// import LayoutBodyWrapper from "./components/LayoutBodyWrapper";

const DashLayout = ({ children }) => {
  const [sidebarCompact, setSidebarCompact] = useState(false);
  const [showMobileSideBar, setShowMobileSideBar] = useState(false);

  const handleCompactToggle = () => setSidebarCompact(!sidebarCompact);

  const handleMobileDrawerToggle = () =>
    setShowMobileSideBar((state) => !state); // dashboard body wrapper custom style

  const customStyle = {
    width: `calc(100% - ${sidebarCompact ? "86px" : "280px"})`,
    marginLeft: sidebarCompact ? "86px" : "280px",
  };

  return (
    <Fragment>
      <Sidebar
        sidebarCompact={sidebarCompact}
        showMobileSideBar={showMobileSideBar}
        setSidebarCompact={handleCompactToggle}
        setShowMobileSideBar={handleMobileDrawerToggle}
      />

      <LayoutBodyWrapper sx={customStyle}>
        <DashboardHeader
          setShowSideBar={handleCompactToggle}
          setShowMobileSideBar={handleMobileDrawerToggle}
        />
        {children || <Outlet />}
      </LayoutBodyWrapper>
    </Fragment>
  );
};

export default DashLayout;
