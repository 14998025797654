import { useState } from "react";

import {
  Box,
  Button,
  IconButton,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";

import MobileSidebar from "./MobileSidebar";
import MultiLevelMenu from "./MultiLevelMenu";

import { ArrowLeftToLine } from "icons";
import { FlexBox, Scrollbar, FlexBetween } from "components";
import { LoginOutlined } from "@mui/icons-material";
import { lightTheme } from "constants";

const TOP_HEADER_AREA = 70;
const BOTTOM_FOOTER_AREA = 80;

const SidebarWrapper = styled(Box)(({ theme, compact }) => ({
  height: "100vh",
  position: "fixed",
  width: compact ? 86 : 280,
  transition: "all .2s ease",
  zIndex: theme.zIndex.drawer,
  backgroundColor: theme.palette.background.paper,
  "&:hover": compact && {
    width: 280,
  },
}));

const NavWrapper = styled(FlexBox)(() => ({
  paddingTop: 46,
  paddingLeft: 16,
  paddingRight: 16,
  height: "100%",
}));

const FooterWrapper = styled(Box)(() => ({
  paddingTop: 16,
  paddingLeft: 16,
  paddingRight: 16,
}));

const StyledArrow = styled(ArrowLeftToLine)(({theme}) => ({
  display: "block",
  color: theme.palette.primary.main
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
})); // -----------------------------------------------------------------------------

// -----------------------------------------------------------------------------
const Sidebar = (props) => {
  const {
    sidebarCompact,
    showMobileSideBar,
    setShowMobileSideBar,
    setSidebarCompact,
  } = props;
  const [onHover, setOnHover] = useState(false);
  const theme = useTheme();
  const downLg = useMediaQuery((theme) => theme.breakpoints.down("lg")); // Activate compact when toggle button clicked and not on hover state

  const COMPACT = sidebarCompact && !onHover ? 1 : 0; //   IF MOBILE

  if (downLg) {
    return (
      <MobileSidebar
        sidebarCompact={!!COMPACT}
        showMobileSideBar={!!showMobileSideBar}
        setShowMobileSideBar={setShowMobileSideBar}
      />
    );
  }

  return (
    <SidebarWrapper
      compact={sidebarCompact}
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => sidebarCompact && setOnHover(false)}
    >
      <FlexBetween pt={3} pr={2} pl={3.4} pb={1} height={TOP_HEADER_AREA}>
        {/* LOGO */}
        <FlexBox>
          {!COMPACT ? (
            <img
              src={
                lightTheme(theme)
                  ? "/static/logo/logo.svg"
                  : "/static/logo/logoDark.svg"
              }
              alt="logo"
              width={160}
            />
          ) : (
            <img
              src={
                lightTheme(theme)
                  ? "/static/logo/logo-sm.svg"
                  : "/static/logo/logoDark-sm.svg"
              }
              alt="logo"
              width={30}
            />
          )}
        </FlexBox>
        <Box mx={"auto"}></Box>

        {/* SIDEBAR COLLAPSE BUTTON */}
        <StyledIconButton
          onClick={setSidebarCompact}
          sx={{
            display: COMPACT ? "none" : "block",
          }}
        >
          <StyledArrow color="primary.main" />
        </StyledIconButton>
      </FlexBetween>

      {/* NAVIGATION ITEMS */}
      <Scrollbar
        autoHide
        clickOnTrack={false}
        sx={{
          overflowX: "hidden",
          height: `calc(100vh - ${TOP_HEADER_AREA + BOTTOM_FOOTER_AREA}px)`,
          maxHeight: `calc(100vh - ${TOP_HEADER_AREA + BOTTOM_FOOTER_AREA}px)`,
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <NavWrapper flexDirection="column" justifyContent="space-between">
          <MultiLevelMenu sidebarCompact={!!COMPACT} />
        </NavWrapper>
      </Scrollbar>

      <FooterWrapper height={BOTTOM_FOOTER_AREA}>
        {!!COMPACT ? (
          <IconButton size="small" onClick={() => {}}>
            <LoginOutlined />
          </IconButton>
        ) : (
          <Button
            fullWidth
            variant="contained"
            onClick={() => {}}
            startIcon={<LoginOutlined />}
          >
            Log out
          </Button>
        )}
      </FooterWrapper>
    </SidebarWrapper>
  );
};

export default Sidebar;
