// ** Redux Imports
import { configureStore } from "@reduxjs/toolkit";

import { apiSlice } from "./api";
import layoutSlice from "./layout";
import authSlice from "pages/auth/store";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authSlice,
    layout: layoutSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});
