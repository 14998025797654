import { PersonChalkboard, UserList } from "icons";

export const navigations = [
  // {
  //   type: "label",
  //   label: "Dashboard",
  // },
  {
    name: "Cards",
    path: "/cards",
    icon: PersonChalkboard,
  },

  {
    name: "Contacts",
    path: "/leads",
    icon: UserList,
  },

  // {
  //   name: "Settings",
  //   path: "/settings",
  //   icon: PersonChalkboard,
  // },
];
