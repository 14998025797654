import { SvgIcon } from "@mui/material";

const Behance = (props) => {
  return (
    <SvgIcon viewBox="0 0 2500 2500" {...props}>
      <circle cx="1250" cy="1250" r="1250"  />
      <path
        fill="#fff"
        d="M883.1 683.6c53.9 0 103.1 4.8 147.6 14.3s82.5 25.1 114.2 46.8c31.7 21.7 56.3 50.5 73.8 86.5s26.2 80.4 26.2 133.3c0 57.1-13 104.7-38.9 142.8-25.9 38.1-64.3 69.3-115 93.6 69.8 20.1 121.9 55.3 156.3 105.5 34.4 50.2 51.6 110.8 51.6 181.7 0 57.1-11.1 106.6-33.3 148.4s-52.1 75.9-89.7 102.3c-37.6 26.5-80.4 46-128.5 58.7-48.4 12.7-98.3 19.1-148.3 19H350V683.6h533.1zm-31.7 458.5c44.4 0 80.9-10.6 109.5-31.7 28.6-21.2 42.9-55.5 42.8-103.1 0-26.4-4.8-48.1-14.3-65s-22.2-30.2-38.1-39.7c-15.9-9.5-34.1-16.1-54.7-19.8-21.2-3.7-42.7-5.6-64.3-5.5H599.2v265l252.2-.2zm14.3 480.7c23.5.1 46.9-2.3 69.8-7.1 22.2-4.8 41.8-12.7 58.7-23.8 16.9-11.1 30.4-26.2 40.5-45.2 10-19 15.1-43.4 15.1-73 0-58.1-16.4-99.7-49.2-124.5-32.8-24.9-76.2-37.3-130.1-37.3H599.2v311l266.5-.1zm746.6-23.8c33.8 32.8 82.5 49.2 146 49.2 45.5 0 84.6-11.4 117.4-34.1 32.8-22.7 52.9-46.8 60.3-72.2h198.3c-31.7 98.4-80.4 168.7-146 211-65.6 42.3-144.9 63.5-238 63.5-64.5 0-122.7-10.3-174.5-30.9-51.8-20.6-95.7-50-131.7-88.1-36-38.1-63.8-83.5-83.3-136.4-19.5-52.9-29.3-111.1-29.3-174.5 0-61.3 10-118.4 30.1-171.3 20.1-52.9 48.7-98.6 85.7-137.2 37-38.6 81.2-69 132.5-91.2 51.3-22.2 108.2-33.3 170.6-33.3 69.8 0 130.6 13.5 182.5 40.5 51.8 27 94.4 63.2 127.7 108.7s57.4 97.3 72.2 155.5c14.8 58.2 20.1 119 15.9 182.5h-591.8c3.1 72.7 21.6 125.6 55.4 158.3zm254.7-431.5c-27-29.6-68-44.4-123-44.4-36 0-65.9 6.1-89.6 18.2-23.8 12.2-42.8 27.2-57.1 45.2-14.3 18-24.3 37-30.1 57.1-5.8 20.1-9.3 38.1-10.3 53.9h366.5c-10.7-57-29.5-100.4-56.4-130zm-348.7-426.4h457.9v127h-457.9v-127z"
      />
    </SvgIcon>
  );
};

export default Behance;
